import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { FormattedMessage, useIntl } from 'react-intl'
import FaqList from '@components/faqlist'
import Headline from '@objects/headline'
import Container from '@objects/container'

import { FAQJsonLd } from 'gatsby-plugin-next-seo'

export const frontmatter = {
  breadcrumbs: [
    { label: 'Downloads & Presse', link: '/downloads-und-presse/' },
  ],
}

function Faqs({ data }) {
  const faqCategoryList = get(data, 'contentfulFaqCategoryList.faqCategories')

  function getAllFaq() {
    let allFaq = []
    faqCategoryList.forEach((faq) => {
      if (!faq.faqs) return
      allFaq.push(...faq.faqs)
    })
    return allFaq
  }

  const allFaqs = getAllFaq()
  const intl = useIntl()
  return (
    <>
      <FAQJsonLd
        questions={allFaqs.map((faq) => ({
          question: faq.question,
          answer: faq.answer.childMarkdownRemark?.html,
        }))}
      />

      <Container nopadding role="region">
        <Headline
          className="mt-8 pt-1 mb-8 md:mb-12"
          level={1}
          ariaLabel={intl.formatMessage({ id: 'faq.headline' })}
        >
          <FormattedMessage id={'faq.headline'} />
        </Headline>
      </Container>
      <FaqList data={faqCategoryList} type="faq" />
    </>
  )
}

Faqs.propTypes = {
  data: PropTypes.any,
}

export default Faqs

export const pageQuery = graphql`
  query FaqPageQuery($locale: String!) {
    contentfulFaqCategoryList(
      identifier: { eq: "faqs" }
      node_locale: { eq: $locale }
    ) {
      id
      title
      faqCategories {
        id
        title
        headline
        faqs {
          id
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
